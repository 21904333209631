//import { useCallback } from "react";

/* surveyJS imports */
import { Dropdown } from "../../dropdown";
// import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import "survey-core/defaultV2.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { Serializer } from "survey-core";
import { FunctionFactory } from "survey-core";
import { marked } from "marked";
import EL from "../helpers/EL";

/* hooks */
import { useState, useEffect, Component } from "react";
/* i18next */
import { Trans, useTranslation } from "react-i18next";
import i18next from "../../glossary";

/* styles */
import styled from "styled-components";
import * as surveyCss from "./style.css";

import { surveySchema } from "./surveySchema";
import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
// import genDoc from "../DocXTemplater";
// import wordTemplate from "../../wordTemplates/results.docx";

StylesManager.applyTheme("defaultV2");
// Serializer.addProperty("question", "popupdescription:text");
Serializer.addProperty("question", "popupknowledge:text");
Serializer.addProperty("page", "popupknowledge:text");

let lng;
// ShowInfoPopup function
function showKnowledge(element) {
  if (lng === "en") {
    document.getElementById("questionKnowledgeText").innerText =
      element.popupknowledge.default;
  } else if (lng === "de") {
    document.getElementById("questionKnowledgeText").innerText =
      element.popupknowledge.de;
  } else
    document.getElementById("questionKnowledgeText").innerText =
      element.popupknowledge.default;
  // document.getElementById("questionKnowledgeText").innerText =
  //   element[i18next.t("popupknowledge")];
  // console.log("element: ", element);
  // document.getElementById("questionKnowledgeVideo").innerText =
  //   element.popupknowledgevideo;
  const popupEl = document.getElementById("questionKnowledgePopup");
  document.getElementById("questionKnowledgePopupClose").onclick = () => {
    popupEl.style.display = "none";
    popupEl.close();
  };
  popupEl.style.display = "inline-block";
  popupEl.showModal();
}

//FunctionFactory.Instance.register("showDescription", showInfoPopup);

//function showInfoPopup(element) {
//  if (document.getElementById("ocLanguageSwitcher").value === "en") {
//    handleOpen({ title: "Info" }, [element.popupdescription.default]);
//  } else handleOpen({ title: "Info" }, [element.popupdescription.de]);
//  document.getElementById("ocModalCancelButton").style.display = "none";
//}

// DiffDates function
FunctionFactory.Instance.register("diffDates", diffDates);


function diffDates([startDate, endDate]) {
  let d2;
 
  let timeDifference;
  let dayDifference;

  if (!startDate) return 0;

  let d1 = new Date(startDate);
  
  if (endDate) {
      d2 = new Date(endDate);

  } else {
      d2 = new Date();
   }

// Calculating the time difference
// of two dates

timeDifference =
d2.getTime() - d1.getTime();

// Calculating the no. of days between
// two dates

dayDifference =
Math.round
    (timeDifference / (1000 * 3600 * 24));

  let totalYears = dayDifference / 365; 
  console.log("totalYears: ", totalYears);

  return (totalYears.toFixed(2));
}


const schema = Serializer.generateSchema();
// console.log("schema: ", schema);

// export function SurveyApp({ currentLang, surveyJson, surveyOnChangeCallback }) {
export function SurveyApp({ surveyJson, surveyOnChangeCallback }) {
  const [isLoading, setIsLoading] = useState(true);
  const [schema, setSchema] = useState({});
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // locale options, presets
  const options = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "de",
      label: "Deutsch",
    },
  ];
  const getSchema = async () => {
    /*
      Using static placeholders for this example
    */
    // set schema
    setSchema(surveySchema);
    // set default locale
    survey.locale = "en";
    // cancel loading
    setIsLoading(false);
  };
  useEffect(() => {
    // Get schema from your internal API here
    getSchema();
  }, []);
  const changeLocale = (newLocale) => {
    // set new locale
    survey.locale = newLocale; //
  };
  const survey = new Model(surveyJson);

  // survey.locale = currentLang;

  const { t, i18n } = useTranslation();
  survey.locale = i18n.language;

  survey.onAfterRenderQuestion.add(function (survey, options) {
    // console.log("survey locale: ", survey.locale);
    //Do nothing if a question contains no description to show in a modal popup
    if (!options.question.popupknowledge) return;
    //Create a 'More Info' button to invoke a modal popup
    let popupButton = document.createElement("button");
    popupButton.type = "button";
    popupButton.className = "btn btn-info btn-xs";
    // popupButton.className = "btn infoIcon";
    // popupButton.innerText = "More Info";
    popupButton.append(
      EL(
        "span",
        { id: "knowledgeButton", className: "btn btn-info btn-xs" },
        "Info"
      )
    );
    const question = options.question;
    // console.log("options.question", question.popupknowledge);
    popupButton.onclick = function () {
      showKnowledge(question);
    };
    //Insert the created 'More Info' button into the rendered question's header
    let header = options.htmlElement.querySelector("h5");
    if (!header) header = options.htmlElement;
    header.appendChild(popupButton);
  });

  survey.onAfterRenderPage.add(function (survey, options) {
    //Do nothing if a page contains no description to show in a modal popup
    if (!options.page.popupknowledge) return;
    //Create a 'More Info' button to invoke a modal popup
    let popupButton = document.createElement("button");
    popupButton.type = "button";
    popupButton.className = "btn btn-info btn-xs";
    popupButton.innerText = "More Info";
    popupButton.append(
      EL(
        "span",
        { id: "knowledgeButton", className: "btn btn-info btn-xs" },
        "Info"
      )
    );
    popupButton.onclick = function () {
      showKnowledge(survey.currentPage);
    };
    //Insert the created 'More Info' button into the rendered page's header
    let header = options.htmlElement.querySelector("h4");
    if (!header || !!header.querySelector(".dialogBox-btn")) return;
    header.appendChild(popupButton);
  });

  // const template = wordTemplate;
  survey.focusFirstQuestionAutomatic = false;
  // document.getElementById("generateButton").style.display = "hidden";

  survey.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    let html = marked.parse(options.text);
    //remove pssible root p tag
    if (html.startsWith("<p>")) {
      html = html.substring(3, html.length - 5);
    }
    options.html = html;
  });
  survey.onValueChanged.add(updateSurveyData);

  survey.onComplete.add(onCompleteFunction);
  /*   const onCompleteFunction = useCallback((sender) => {
    const results = JSON.stringify(sender.data);
    alert(results);
    survey.clear();
    debugger;
    survey.scrollIntoView();
  }, []); */

  function onCompleteFunction(sender) {
    // const results = JSON.stringify(survey.data);

    // genDoc(survey.data, template, "Survey Response Summary.docx");
    // console.log("survey data :", survey.data);
    // alert(results);
    survey.clear();
    document.getElementById("surveys").scrollIntoView();
    window.scrollBy(0, -64);
  }
  function updateSurveyData() {
    surveyOnChangeCallback(survey.data);
  }
  const html = isLoading ? (
    <p> Loading...</p>
  ) : (
    <Survey model={survey} css={surveyCss} />
  );
  // const { t, i18n } = useTranslation();
  lng = i18n.language;

  // Dropdown(
  //   "Choose your language",
  //   { options },
  //   survey.getLocale() === "" ? options[0].value : survey.getLocale(),
  //   { changeLocale }
  // );
  return (
    <div class="container">
      {/* <div class="dropdown">
        <Dropdown
          label={t("selectLanguage")}
          options={options}
          value={
            survey.getLocale() === "" ? options[0].value : survey.getLocale()
          }
          changeLocale={changeLocale}
        />
      </div> */}
      <div className="survey">{html}</div>
      {/* <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <p>Info</p>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p>
              <Trans i18nKey="popupDescriptionNoMore10"></Trans>
            </p>
          </Typography>
        </Box>
      </Modal> */}
    </div>
  );
  // return <Survey id="surveys" model={survey} />;
}

export default SurveyApp;

export const SurveyContainer = styled.div`
  /* height: 800px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #001822;
  color: #ffffff;
  font-family: "Jost", sans-serif;

  @media screen and (max-width: 768px) {
    /*  height: 1100px; */
  }

  @media screen and (max-width: 480px) {
    /* height: 1300px; */
  }
`;

export const SurveyH1 = styled.h1`
  font-size: 2.5rem;
  color: #ffffff;
  margin-top: 64px;

  @media screen and (max-width: 480px) {
    padding: 15px;
    font-size: 1.7rem;
  }
`;

export const SurveyP = styled.p`
  font-size: 1rem;
  text-align: justify;
  color: #ffffff;
  max-width: calc(84 * var(--base-unit, 8px));
  margin-left: auto;
  margin-right: auto;
`;
